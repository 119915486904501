body {
  margin: 0;
  height: 100%;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-size: cover;
  background-image: url(public/images/sunlightthroughleaves.png);
  /*
background: rgb(19,122,59);
background: linear-gradient(180deg, rgba(19,122,59,1) 0%, rgba(115,121,9,0.9864320728291317) 54%, rgba(0,212,255,1) 100%);
*/
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

#root {
	height: 100%;
}

.mostOuterContainer {
	height: 100%;
}

.mini-select {
    padding: 0;
    margin: 0;
    line-height: 20px;
    font-size: 8pt;
}

select > option {
    font-size: 8pt;
}
